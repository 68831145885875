import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Placeholder from '../assets/placeholder.png';
import AltaBank from '../assets/ads/altabank.jpg';
import Lexus from '../assets/ads/LHMiller-Lexus.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "utah-symphony",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#utah-symphony",
        "aria-label": "utah symphony permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Utah Symphony`}</h1>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Please tap an image below to view the digital publication.`}</p>
    </blockquote>
    <h2>Tonight’s Concert</h2>
    {
      /******************CURRENT PUBLICATIONS******************/
    }
    {
      /******************CURRENT PUBLICATIONS******************/
    }
    {
      /******************CURRENT PUBLICATIONS******************/
    }
    <section className="home-list">
      <Link to="/mar-apr-2023" alt="Link to March April 2023 Utah Symphony publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAwL/2gAMAwEAAhADEAAAAdclTLjA3JYz6U0kRn//xAAcEAACAgIDAAAAAAAAAAAAAAABAgARAxASEyH/2gAIAQEAAQUCLQrcyCn8M7KjvzZ20K0Nf//EABURAQEAAAAAAAAAAAAAAAAAAAIg/9oACAEDAQE/AVH/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwEj/8QAGxAAAQQDAAAAAAAAAAAAAAAAABARITEBMlH/2gAIAQEABj8CyrlwOlkJsdP/xAAcEAACAgMBAQAAAAAAAAAAAAAAAREhMVGBQXH/2gAIAQEAAT8his3XhYpdK6seEyNlk3awWIYs9EYWyQ0A4eZILThW/o//2gAMAwEAAgADAAAAEJ/wQ//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAEDAQE/EIawg3OEP//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxAI7Cf/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFhUZH/2gAIAQEAAT8QNIA6cVX2PYOGReIGGuEqkHBe5bbowPXcIlXjfYIGaVezQ0l6ziHIAPZftW8jbV5sxVv4Kn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b4a42fe80967e28e96c1c05d9465f138/8ac56/mar-apr-2023.webp 240w", "/static/b4a42fe80967e28e96c1c05d9465f138/d3be9/mar-apr-2023.webp 480w", "/static/b4a42fe80967e28e96c1c05d9465f138/87ca7/mar-apr-2023.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/b4a42fe80967e28e96c1c05d9465f138/09b79/mar-apr-2023.jpg 240w", "/static/b4a42fe80967e28e96c1c05d9465f138/7cc5e/mar-apr-2023.jpg 480w", "/static/b4a42fe80967e28e96c1c05d9465f138/1a144/mar-apr-2023.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/b4a42fe80967e28e96c1c05d9465f138/1a144/mar-apr-2023.jpg",
                "alt": "Utah Symphony March April 2023 Cover",
                "title": "Utah Symphony March April 2023 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Mar/Apr 2023</div>
      </Link>
      <Link to="/noorda-2022" alt="Link to the 2022-2023 Utah Symphony Noorda Concert Series." className="cover-filter" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAgX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAGXj04MMQ86o5dFpoGn/8QAGxAAAgIDAQAAAAAAAAAAAAAAAgMAAQQREhP/2gAIAQEAAQUCSAkCx3GVosRQ2k7tT2X0ScnzBpUTJ1A1Ln//xAAWEQEBAQAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BcjP/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwEj/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECERAhIjGB/9oACAEBAAY/ApWPCk+2SinxssUK9LWds0f/xAAfEAACAgIBBQAAAAAAAAAAAAAAAREhMUEQUWFxgZH/2gAIAQEAAT8hbSy9WRqKnBTVjQtOWP4NSRokxMZt+TUfNu4x+3jYSMjCeg//2gAMAwEAAgADAAAAEF8Hff/EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxA2DAEz/8QAFxEBAQEBAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxDQuw7P/8QAHxABAAMBAAICAwAAAAAAAAAAAQARITFBoVFhccHh/9oACAEBAAE/EBHiN8VL5ytHfF7Gziw/CUhAbzX8gjKXEB31cYXAN6jqBAfm2qqhCK+7bp9TTpUQ4KzxKbQ9KcnlLp3f3P/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2bce56aac3fd87c44188694f17db9229/8ac56/noorda-2022.webp 240w", "/static/2bce56aac3fd87c44188694f17db9229/d3be9/noorda-2022.webp 480w", "/static/2bce56aac3fd87c44188694f17db9229/87ca7/noorda-2022.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2bce56aac3fd87c44188694f17db9229/09b79/noorda-2022.jpg 240w", "/static/2bce56aac3fd87c44188694f17db9229/7cc5e/noorda-2022.jpg 480w", "/static/2bce56aac3fd87c44188694f17db9229/1a144/noorda-2022.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/2bce56aac3fd87c44188694f17db9229/1a144/noorda-2022.jpg",
                "alt": "Utah Symphony Noorda 2022-2023 Season publication cover Featuring Madeline Adkins",
                "title": "Utah Symphony Noorda 2022-2023 Season publication cover Featuring Madeline Adkins",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Noorda 2022-23</div>
      </Link>
    </section>
    <hr />
    {
      /******************PAST PUBLICATIONS******************/
    }
    {
      /******************PAST PUBLICATIONS******************/
    }
    {
      /******************PAST PUBLICATIONS******************/
    }
    <div id="PastAnchor">
      <h4 {...{
        "id": "please-tap-a-button-below-to-view-the-past-publications",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h4" {...{
          "href": "#please-tap-a-button-below-to-view-the-past-publications",
          "aria-label": "please tap a button below to view the past publications permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Please tap a button below to view the past publications.`}</h4>
      <details>
        <summary>2023 Concerts</summary>
        <section className="home-list past">
          <Link to="/jan-feb-2023" alt="Link to January February 2023 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDBAL/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHO7TjTOMByj05oIVf/xAAdEAACAgIDAQAAAAAAAAAAAAABAgARAxIQISJB/9oACAEBAAEFAgjMFW44pt6VMnnIdm7J+zcwUeP/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEp/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BIf/EABwQAAIBBQEAAAAAAAAAAAAAAAABERAgITFR4f/aAAgBAQAGPwJtI0cIPCbcun//xAAdEAADAAICAwAAAAAAAAAAAAAAAREhcRBhMUFR/9oACAEBAAE/IbAkuyGqzBTQSOdRTzKR6dCuLga2O0vnEM+x02D0adP/2gAMAwEAAgADAAAAEBgJTP/EABYRAQEBAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxCBEwtz/8QAFxEBAQEBAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPxDVnBP/xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhMUFRYXGx/9oACAEBAAE/EDbrRUFvktUEoc6uMz1DyjoRtXG11xDG6teAvfsAHSBkr8heJBMBzLCqHChn3cGgUE2gWcm40cTuqn//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/2b64af2b41b15339aa6303b86ea43ffa/8ac56/jan-feb-2023.webp 240w", "/static/2b64af2b41b15339aa6303b86ea43ffa/d3be9/jan-feb-2023.webp 480w", "/static/2b64af2b41b15339aa6303b86ea43ffa/87ca7/jan-feb-2023.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/2b64af2b41b15339aa6303b86ea43ffa/09b79/jan-feb-2023.jpg 240w", "/static/2b64af2b41b15339aa6303b86ea43ffa/7cc5e/jan-feb-2023.jpg 480w", "/static/2b64af2b41b15339aa6303b86ea43ffa/1a144/jan-feb-2023.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/2b64af2b41b15339aa6303b86ea43ffa/1a144/jan-feb-2023.jpg",
                    "alt": "Utah Symphony January February 2023 Cover",
                    "title": "Utah Symphony January February 2023 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Jan/Feb 2023</div>
          </Link>
        </section>
      </details>
      <details>
        <summary>2022 Concerts</summary>
        <section className="home-list past">
          <Link to="/nov-dec-2022" alt="Link to November December 2022 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAFSvSTHIgrNhRexe4DH/8QAHBAAAgIDAQEAAAAAAAAAAAAAAgMBEgAEERAT/9oACAEBAAEFAlLEgUPcOOFqKGUkXyaRWlGxQGTZmWwOef/EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPwFyM//EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAECAQE/ASP/xAAdEAABBAIDAAAAAAAAAAAAAAABAAIQESFBEjGB/9oACAEBAAY/AnEo4jkdp7NXhWgyvVYnuP/EAB8QAAMBAAIBBQAAAAAAAAAAAAERIQAxYRBBcYGR0f/aAAgBAQABPyEVNHFyUIVdKgnphobJH6xRDWAO/b4qoua7wzt78KjFgIMr8b2L3//aAAwDAQACAAMAAAAQ6O5C/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAwEBPxCSEWF//8QAFxEBAQEBAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxDQuw7P/8QAHxABAAIDAAEFAAAAAAAAAAAAAQARITFhUUFxgcHh/9oACAEBAAE/EL1RZ4VDZounczV8BrTJKViM6y/Jcza0IDn7jOhFDa2WtgfN0qoEiddtuTkBCkr3jQGj08zMlvVmUGKPmqn/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/982dc6cde45448c939bd096c4e3338e2/8ac56/nov-dec-2022.webp 240w", "/static/982dc6cde45448c939bd096c4e3338e2/d3be9/nov-dec-2022.webp 480w", "/static/982dc6cde45448c939bd096c4e3338e2/87ca7/nov-dec-2022.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/982dc6cde45448c939bd096c4e3338e2/09b79/nov-dec-2022.jpg 240w", "/static/982dc6cde45448c939bd096c4e3338e2/7cc5e/nov-dec-2022.jpg 480w", "/static/982dc6cde45448c939bd096c4e3338e2/1a144/nov-dec-2022.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/982dc6cde45448c939bd096c4e3338e2/1a144/nov-dec-2022.jpg",
                    "alt": "Utah Symphony November December 2022 Cover",
                    "title": "Utah Symphony November December 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Nov/Dec 2022</div>
          </Link>
          <Link to="/bernadette-peters-2022" alt="Link to Bernadette Peters with the Utah Symphony publication 2022." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHN5esbINwKi1acLAuN/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxESE//aAAgBAQABBQJVapVNGHmRqbHHg0rciU9fR2dH/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPwGMhc//xAAYEQEBAAMAAAAAAAAAAAAAAAAAEgECEf/aAAgBAgEBPwG9VYS4/8QAHhAAAQMEAwAAAAAAAAAAAAAAAQAQERIgITEygZH/2gAIAQEABj8Cl8Se1x9KikNsW//EAB0QAQABBAMBAAAAAAAAAAAAAAEAESExcRBRYUH/2gAIAQEAAT8hUasnUuOTviHxaQGpY+kBVLolZX4ME2I1mZmNWS8OD//aAAwDAQACAAMAAAAQo+wM/8QAFxEBAQEBAAAAAAAAAAAAAAAAEQABQf/aAAgBAwEBPxDtOQTt/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERQWH/2gAIAQIBAT8QdJpyJTrIP//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQRBRYaH/2gAIAQEAAT8QExCnJmBuhyjX54GGvhMYWAlyocqtQaMw2oJ1BZNZoIfp5dYyoKE1lFBHeA+zJ9Z//9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/9ea34618d4defa1269a472fe86c21165/8ac56/bernadette-peters.webp 240w", "/static/9ea34618d4defa1269a472fe86c21165/d3be9/bernadette-peters.webp 480w", "/static/9ea34618d4defa1269a472fe86c21165/87ca7/bernadette-peters.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/9ea34618d4defa1269a472fe86c21165/09b79/bernadette-peters.jpg 240w", "/static/9ea34618d4defa1269a472fe86c21165/7cc5e/bernadette-peters.jpg 480w", "/static/9ea34618d4defa1269a472fe86c21165/1a144/bernadette-peters.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/9ea34618d4defa1269a472fe86c21165/1a144/bernadette-peters.jpg",
                    "alt": "Utah Symphony Bernadette Peters with Utah Symphony 2022 Cover",
                    "title": "Utah Symphony Bernadette Peters with Utah Symphony 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Bernadette Peters 2022</div>
          </Link>
          <Link to="/celtic-woman-2022" alt="Link to Celtic Woman A Christmas Symphony publication 2022." className="cover-filter" mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAZyKLCSXAQ3N3F0Gcr//xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEREgMT/9oACAEBAAEFAo5LK5oo8YF42SuCznLDPc6Nmj//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/AYyFz//EABgRAQEAAwAAAAAAAAAAAAAAAAASAQIR/9oACAECAQE/Ab1VhLj/xAAeEAABAwQDAAAAAAAAAAAAAAABABAhAhESIDEyQf/aAAgBAQAGPwLtL+lRQFbENyNf/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITEQcUFRYf/aAAgBAQABPyEA4I1FrPEy0UfamI0PawVZeiXL8DBOCOtzcxtszDg//9oADAMBAAIAAwAAABDr7Az/xAAXEQEBAQEAAAAAAAAAAAAAAAARAAFB/9oACAEDAQE/EO05BO3/xAAXEQEBAQEAAAAAAAAAAAAAAAARAAFh/9oACAECAQE/ENQblHNdhf/EAB0QAQACAgMBAQAAAAAAAAAAAAEAESExQVFhEIH/2gAIAQEAAT8QqfsFOn2VQfpp+KC5FjDG0NLZYtUNjUMqCcULVYmAh9O3EZUFhWJYsEcwHslep//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/bc7e3eded66b1b8bd283b4663b531174/8ac56/celtic-woman.webp 240w", "/static/bc7e3eded66b1b8bd283b4663b531174/d3be9/celtic-woman.webp 480w", "/static/bc7e3eded66b1b8bd283b4663b531174/87ca7/celtic-woman.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/bc7e3eded66b1b8bd283b4663b531174/09b79/celtic-woman.jpg 240w", "/static/bc7e3eded66b1b8bd283b4663b531174/7cc5e/celtic-woman.jpg 480w", "/static/bc7e3eded66b1b8bd283b4663b531174/1a144/celtic-woman.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/bc7e3eded66b1b8bd283b4663b531174/1a144/celtic-woman.jpg",
                    "alt": "Utah Symphony Celtic Woman A Christmas Symphony 2022 Cover",
                    "title": "Utah Symphony Celtic Woman A Christmas Symphony 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Celtic Woman</div>
          </Link>
          <Link to="/soulful-holiday-2022" alt="Link to A Soulful Holiday with the Utah Symphony publication 2022." className="cover-filter" mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAc9N7DJNwENm7i0E5X//xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEREgMT/9oACAEBAAEFAl5LMMtTR4wLxslcFnOWGe50bNH/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/AYyFz//EABgRAQEAAwAAAAAAAAAAAAAAAAASAQIR/9oACAECAQE/Ab1VhLj/xAAdEAABAwUBAAAAAAAAAAAAAAABABAxAhESICFB/9oACAEBAAY/Apf0rlAVsQ0jX//EAB0QAQACAgMBAQAAAAAAAAAAAAEAESExEGFxQVH/2gAIAQEAAT8hArVZhC674mWijupiND9WCrL4S5fwYJwR5ubmNtmYcH//2gAMAwEAAgADAAAAEC/sDP/EABcRAQEBAQAAAAAAAAAAAAAAABEAASH/2gAIAQMBAT8Q5TkE7f/EABcRAQEBAQAAAAAAAAAAAAAAABEAAWH/2gAIAQIBAT8Q1BuUc12F/8QAHBABAQEBAAMBAQAAAAAAAAAAAREAITFBURBh/9oACAEBAAE/EA9gFLhcWOUS/iBe5c4xqHhbqLUGjMdUE8gsnNwIf6fL1mVBQnNRQR7wP00/N//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/3afa9221040a77676797bc4679a14d21/8ac56/soulful-holiday.webp 240w", "/static/3afa9221040a77676797bc4679a14d21/d3be9/soulful-holiday.webp 480w", "/static/3afa9221040a77676797bc4679a14d21/87ca7/soulful-holiday.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/3afa9221040a77676797bc4679a14d21/09b79/soulful-holiday.jpg 240w", "/static/3afa9221040a77676797bc4679a14d21/7cc5e/soulful-holiday.jpg 480w", "/static/3afa9221040a77676797bc4679a14d21/1a144/soulful-holiday.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/3afa9221040a77676797bc4679a14d21/1a144/soulful-holiday.jpg",
                    "alt": "Utah Symphony A Soulful Holiday with the Utah Symphony 2022 Cover",
                    "title": "Utah Symphony A Soulful Holiday with the Utah Symphony 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">A Soulful Holiday</div>
          </Link>
          <Link to="/sept-oct-2022" alt="Link to September October 2022 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "157.50000000000003%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAgABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABhVXkuEaPPL50l0kxT//EABwQAAEEAwEAAAAAAAAAAAAAAAEAAgMSEBEhMf/aAAgBAQABBQIR7DYbKqbJVsZ7vEfSfVYhWK//xAAXEQADAQAAAAAAAAAAAAAAAAABAhAx/9oACAEDAQE/ATsaf//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ASH/xAAdEAACAgEFAAAAAAAAAAAAAAABEAAxAhEyQVFh/9oACAEBAAY/AtsPiAmVDlnXpWJa/8QAHRABAQEBAAEFAAAAAAAAAAAAAREAMVEQIUFhkf/aAAgBAQABPyGO0qZLCH29J+EPGds045q+TXRAZX7kiEyE5YT7Yo93/9oADAMBAAIAAwAAABBH3UL/xAAXEQEBAQEAAAAAAAAAAAAAAAABEBEx/9oACAEDAQE/EM9EQs//xAAYEQACAwAAAAAAAAAAAAAAAAAQEQEhMf/aAAgBAgEBPxCGqGB//8QAHxABAAMAAgEFAAAAAAAAAAAAAQARITFBgRBRYXGh/9oACAEBAAE/EAGtIRdhIRyHr6aab8nfuUEgehm54gmqrt8RAi9ToIgnaKICIR94izc6bz8jEah64Yob+Gf/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e2b42e25c0fcfb520ac1a17a1421a621/8ac56/sept-oct-2022.webp 240w", "/static/e2b42e25c0fcfb520ac1a17a1421a621/d3be9/sept-oct-2022.webp 480w", "/static/e2b42e25c0fcfb520ac1a17a1421a621/87ca7/sept-oct-2022.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e2b42e25c0fcfb520ac1a17a1421a621/09b79/sept-oct-2022.jpg 240w", "/static/e2b42e25c0fcfb520ac1a17a1421a621/7cc5e/sept-oct-2022.jpg 480w", "/static/e2b42e25c0fcfb520ac1a17a1421a621/1a144/sept-oct-2022.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/e2b42e25c0fcfb520ac1a17a1421a621/1a144/sept-oct-2022.jpg",
                    "alt": "Utah Symphony September October 2022 Cover",
                    "title": "Utah Symphony September October 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Sept/Oct 2022</div>
          </Link>
          <Link to="/may-2022" alt="Link to May 2022 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAADBP/aAAwDAQACEAMQAAABNR0FTbEB08iLGkl5xwdX/8QAHhAAAQQBBQAAAAAAAAAAAAAAAQACAxEzEBITISL/2gAIAQEAAQUCBai5mktcigxyg76UGOvb6eh0v//EABkRAAIDAQAAAAAAAAAAAAAAAAACAQMhEf/aAAgBAwEBPwFrdF2OjVNMi5HD/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACMf/aAAgBAgEBPwEzMbJ7f//EABkQAAIDAQAAAAAAAAAAAAAAABAhAAESMf/aAAgBAQAGPwJmzanBqMf/xAAeEAACAgICAwAAAAAAAAAAAAABEQAhMXFBURCR0f/aAAgBAQABPyEI/UQpe4wcYnClvvxj2YQocxzyepQXVwAjEEdQR6KqgImTuf/aAAwDAQACAAMAAAAQDwGw/8QAGhEAAwADAQAAAAAAAAAAAAAAAAERMUFRYf/aAAgBAwEBPxCLKY9Gn0NLFm2j/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/ENSGKQDJam//xAAgEAEAAwACAQUBAAAAAAAAAAABABEhMUFRYXGBobHw/9oACAEBAAE/EAQCzmx+S2LV8Ajo+649Iciej2jV4/U/teZb5MWcud7ACUHkOnb8QtNpOldy0i4CCsm4t1IafspcDLVs/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/8dca1048691b37855b9ca23dbe6501fc/8ac56/may-cover-2022.webp 240w", "/static/8dca1048691b37855b9ca23dbe6501fc/d3be9/may-cover-2022.webp 480w", "/static/8dca1048691b37855b9ca23dbe6501fc/87ca7/may-cover-2022.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/8dca1048691b37855b9ca23dbe6501fc/09b79/may-cover-2022.jpg 240w", "/static/8dca1048691b37855b9ca23dbe6501fc/7cc5e/may-cover-2022.jpg 480w", "/static/8dca1048691b37855b9ca23dbe6501fc/1a144/may-cover-2022.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/8dca1048691b37855b9ca23dbe6501fc/1a144/may-cover-2022.jpg",
                    "alt": "Utah Symphony May 2022 Cover",
                    "title": "Utah Symphony May 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">May 2022</div>
          </Link>
          <Link to="/mar-apr-2022" alt="Link to March April 2022 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAXAQADAQAAAAAAAAAAAAAAAAABAwQA/9oADAMBAAIQAxAAAAGT8rLU4hp4VMNNABN//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAEQMSEyEx/9oACAEBAAEFAlqFkqLtzPi6HnjjMtipoDOJIOp//8QAGREAAgMBAAAAAAAAAAAAAAAAAAECAxEh/9oACAEDAQE/AVTq0lx4RtSRLr0//8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8BIf/EAB0QAAEEAgMAAAAAAAAAAAAAAAEAEBEhQVECEmH/2gAIAQEABj8CsPAzpHlTE2B4gInfZUIVyst//8QAHBAAAwEBAAMBAAAAAAAAAAAAAAERMSFBUXGB/9oACAEBAAE/IWsKUSv0e8UNk+/wXrt8aLlPVwtZ5H5DiDK9cQ05Lg23SX0TxlIh/9oADAMBAAIAAwAAABDc4j3/xAAZEQEBAQADAAAAAAAAAAAAAAABABFBUaH/2gAIAQMBAT8QyQ+Q36QgmKOb/8QAGBEAAwEBAAAAAAAAAAAAAAAAARARITH/2gAIAQIBAT8Qswo11f/EAB0QAQEAAgMBAQEAAAAAAAAAAAERADEhQWFRgZH/2gAIAQEAAT8QGRGbbT+ZuifAZLyB8W4genN30/vWSQY1zm9Xu4UqR08xY6Wj2nDFzgUJJHXEp6szYB0hbMWhFUW3JKp6OsCBXm85/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/c0c91165951e69e7a0f33fc852a8a246/8ac56/mar-apr-2022.webp 240w", "/static/c0c91165951e69e7a0f33fc852a8a246/d3be9/mar-apr-2022.webp 480w", "/static/c0c91165951e69e7a0f33fc852a8a246/87ca7/mar-apr-2022.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/c0c91165951e69e7a0f33fc852a8a246/09b79/mar-apr-2022.jpg 240w", "/static/c0c91165951e69e7a0f33fc852a8a246/7cc5e/mar-apr-2022.jpg 480w", "/static/c0c91165951e69e7a0f33fc852a8a246/1a144/mar-apr-2022.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/c0c91165951e69e7a0f33fc852a8a246/1a144/mar-apr-2022.jpg",
                    "alt": "Utah Symphony March / April 2022 Cover",
                    "title": "Utah Symphony March / April 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">March / April 2022</div>
          </Link>
          <Link to="/cirque-2022" alt="Link to Cirque de la Symphonie Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHO4dsMk3AotWnFYFyv/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAECERIDE//aAAgBAQABBQJRGVCSw+KJ8dHPBp5uir19HZ0f/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPwGMhXn/xAAYEQEBAAMAAAAAAAAAAAAAAAAAEgECEf/aAAgBAgEBPwG9VYS4/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACEBEgITEyQf/aAAgBAQAGPwLstOuPStMCotEcjH//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMRBxQVFh/9oACAEBAAE/IXFSNZmyDiZaKPtTFaHtYCseiXL8DBOCOtzcxtszDg//2gAMAwEAAgADAAAAEC/uPP/EABcRAQEBAQAAAAAAAAAAAAAAAAARIQH/2gAIAQMBAT8QyWwi9f/EABcRAQEBAQAAAAAAAAAAAAAAABEAAWH/2gAIAQIBAT8Q1BuUc12F/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBURBh/9oACAEBAAE/EF9OwXTByTJg578UF2WMILQ0tlqlQ2NQyoJsoWqxMBD+nbkZUFhWJsAjsJ1ZK+T/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/ab6bd28d129cf10713380a41ca9cc043/8ac56/cirque-cover.webp 240w", "/static/ab6bd28d129cf10713380a41ca9cc043/d3be9/cirque-cover.webp 480w", "/static/ab6bd28d129cf10713380a41ca9cc043/87ca7/cirque-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/ab6bd28d129cf10713380a41ca9cc043/09b79/cirque-cover.jpg 240w", "/static/ab6bd28d129cf10713380a41ca9cc043/7cc5e/cirque-cover.jpg 480w", "/static/ab6bd28d129cf10713380a41ca9cc043/1a144/cirque-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/ab6bd28d129cf10713380a41ca9cc043/1a144/cirque-cover.jpg",
                    "alt": "Utah Symphony Cirque de la Symphony Cover",
                    "title": "Utah Symphony Cirque de la Symphony Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Cirque de la Symphonie</div>
          </Link>
          <Link to="/sherlock-2022" alt="Link to Sherlock Holmes and the Case of the Missing Maestro Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAZE3acg7gIbNuLQTlf/EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMSEf/aAAgBAQABBQJalK2V5HIFPBUWhXE7Ky0Z+nU3NT//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/AYyFef/EABcRAQEBAQAAAAAAAAAAAAAAAAASARH/2gAIAQIBAT8BvFYlx//EAB4QAAIBAwUAAAAAAAAAAAAAAAERAAIQIRIgMUGR/9oACAEBAAY/AhjqO2CSo9PsRpFuRt//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMRBBcWH/2gAIAQEAAT8hsfNtBoWZquIKBfQVNSt9qArHwly+hgnBHm5uY22Zhwf/2gAMAwEAAgADAAAAEK/uPP/EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAEDAQE/EKLhZhCs/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERYf/aAAgBAgEBPxB3hkSnWQf/xAAdEAEBAAMBAQADAAAAAAAAAAABEQAhMUFREGGR/9oACAEBAAE/ENwth5fvuDDoBGudv4HDxA1H8xiRbUt8yqlBozDagmkFk1mgh/Z68xlQUJrOgEe4TymT8z//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/fba97477a2639736058328bff0e8c348/8ac56/sherlock-cover.webp 240w", "/static/fba97477a2639736058328bff0e8c348/d3be9/sherlock-cover.webp 480w", "/static/fba97477a2639736058328bff0e8c348/87ca7/sherlock-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/fba97477a2639736058328bff0e8c348/09b79/sherlock-cover.jpg 240w", "/static/fba97477a2639736058328bff0e8c348/7cc5e/sherlock-cover.jpg 480w", "/static/fba97477a2639736058328bff0e8c348/1a144/sherlock-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/fba97477a2639736058328bff0e8c348/1a144/sherlock-cover.jpg",
                    "alt": "Utah Symphony Sherlock Holmes and the Case of the Missing Maestro Cover",
                    "title": "Utah Symphony Sherlock Holmes and the Case of the Missing Maestro Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Sherlock Holmes and the Case of the Missing Maestro</div>
          </Link>
          <Link to="/noorda2021" alt="Link to Noorda 2021–22 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAIDAQQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQP/2gAMAwEAAhADEAAAAebTUsUQUaUKYJzFGn//xAAeEAACAgAHAAAAAAAAAAAAAAABAgADEBESEyMxQf/aAAgBAQABBQJdE4YZ6ctuAEwVtD2tjKpsLYf/xAAWEQADAAAAAAAAAAAAAAAAAAABAhD/2gAIAQMBAT8BLQrP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHBAAAgIDAQEAAAAAAAAAAAAAAREAIQIQIhJC/9oACAEBAAY/AunPqVpV60hizKBGQlxMqdHX/8QAHRAAAgIDAAMAAAAAAAAAAAAAAREAMSFBURBhkf/aAAgBAQABPyHDs9RppEZ01DFMEnJ7BUsXUrEwD1CjHfBvKi1FsApdB+eP/9oADAMBAAIAAwAAABAHxcz/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EEFI5Kuxf//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxBJk//EAB8QAQACAgICAwAAAAAAAAAAAAEAESExQVFhcZGh4f/aAAgBAQABPxBE3v2aHmKbJ3upti3ydpGgovWO4ltGFcOh5/IrDVYmP17A+zzKCV2V0e9P0y1wLum/EcOKoMvkjNp1QgKOOI1eNcT/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/161be3cbea0a138eec19160c80cf39a9/8ac56/noorda.webp 240w", "/static/161be3cbea0a138eec19160c80cf39a9/d3be9/noorda.webp 480w", "/static/161be3cbea0a138eec19160c80cf39a9/87ca7/noorda.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/161be3cbea0a138eec19160c80cf39a9/09b79/noorda.jpg 240w", "/static/161be3cbea0a138eec19160c80cf39a9/7cc5e/noorda.jpg 480w", "/static/161be3cbea0a138eec19160c80cf39a9/1a144/noorda.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/161be3cbea0a138eec19160c80cf39a9/1a144/noorda.jpg",
                    "alt": "Utah Symphony Noorda 2021–22 Cover",
                    "title": "Utah Symphony Noorda 2021–22 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Noorda 2021–22</div>
          </Link>
          <Link to="/carnival-2022" alt="Link to Carnival of the Animals Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHO5csMk3AotWnFYFyv/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxESE//aAAgBAQABBQJVxalXiw8URp0ceDStyJT19HZ0f//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8BjIV5/8QAGBEBAQADAAAAAAAAAAAAAAAAABIBAhH/2gAIAQIBAT8BvVWEuP/EAB0QAAEEAgMAAAAAAAAAAAAAAAEAAhARICEiMUH/2gAIAQEABj8CHJaNx6VpgVFojsY//8QAHRABAAICAgMAAAAAAAAAAAAAAQARITEQcUFRYf/aAAgBAQABPyFSwUh3cKZaKPtTFaHtYCseiXL8DBOCOtzcxtszDg//2gAMAwEAAgADAAAAEO/uPP/EABcRAQEBAQAAAAAAAAAAAAAAAAARIQH/2gAIAQMBAT8QyWwi9f/EABcRAQEBAQAAAAAAAAAAAAAAABEAAWH/2gAIAQIBAT8Q1BuUc12F/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUWEQ/9oACAEBAAE/EHplD4ymbPIbDv4oLkWMILQ1blqlQ2NQyoJsoWqxMBD6duIyoLCsTYBHMJ1ZK9T/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/f3082a014dd1e6d8fd82a20b21af3d17/8ac56/carnival-cover.webp 240w", "/static/f3082a014dd1e6d8fd82a20b21af3d17/d3be9/carnival-cover.webp 480w", "/static/f3082a014dd1e6d8fd82a20b21af3d17/87ca7/carnival-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/f3082a014dd1e6d8fd82a20b21af3d17/09b79/carnival-cover.jpg 240w", "/static/f3082a014dd1e6d8fd82a20b21af3d17/7cc5e/carnival-cover.jpg 480w", "/static/f3082a014dd1e6d8fd82a20b21af3d17/1a144/carnival-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/f3082a014dd1e6d8fd82a20b21af3d17/1a144/carnival-cover.jpg",
                    "alt": "Utah Symphony Carnival of Animals Cover",
                    "title": "Utah Symphony Carnival of Animals Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Carnival of Animals</div>
          </Link>
          <Link to="/jan-feb2022" alt="Link to Jan/Feb 2021–22 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "387px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAEDAgT/xAAXAQADAQAAAAAAAAAAAAAAAAACAwQB/9oADAMBAAIQAxAAAAHx65C3NVCzmznFPD//xAAbEAACAwADAAAAAAAAAAAAAAAAAQIRMQMhIv/aAAgBAQABBQKDjblx0xLzhLUyUrNEkUh9n//EABYRAQEBAAAAAAAAAAAAAAAAAAABEv/aAAgBAwEBPwHKtK//xAAZEQADAQEBAAAAAAAAAAAAAAAAARECAyH/2gAIAQIBAT8BfT0y6qPm6ZUR/8QAHRAAAQQCAwAAAAAAAAAAAAAAAAEQESECMjFhkf/aAAgBAQAGPwK8TUomRZ2ZROmv05b/xAAdEAACAgMBAQEAAAAAAAAAAAABEQAhMUFRYYGR/9oACAEBAAE/IQAs9jBW/IhNF9l5oAwOHsdBcVuZSiHd5iBcJUCdxERe4UJ2C8hJpfUWOs7hM1P/2gAMAwEAAgADAAAAEOgHQ//EABcRAQEBAQAAAAAAAAAAAAAAAAERACH/2gAIAQMBAT8QOMIzAkyqu//EABkRAQEBAAMAAAAAAAAAAAAAAAEAESExYf/aAAgBAgEBPxDlGde2BHpyYRv/xAAdEAEBAAIDAQEBAAAAAAAAAAABEQAhMUFhUXGh/9oACAEBAAE/EPAYptfzjGia9SEfO9Ym6ZqbYzGNgbWcutz3JIRI0Eit9ON4Kkeouh7cICMtEsjD+4hgZ1RRLLPnf7ioQYBDQylD5RZ5MdDddNvOcsyF5i7fuf/Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/889ac0ea6b844dd588d7f47daa03e672/8ac56/jan-feb.webp 240w", "/static/889ac0ea6b844dd588d7f47daa03e672/bbd0d/jan-feb.webp 387w"],
                    "sizes": "(max-width: 387px) 100vw, 387px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/889ac0ea6b844dd588d7f47daa03e672/09b79/jan-feb.jpg 240w", "/static/889ac0ea6b844dd588d7f47daa03e672/05733/jan-feb.jpg 387w"],
                    "sizes": "(max-width: 387px) 100vw, 387px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/889ac0ea6b844dd588d7f47daa03e672/05733/jan-feb.jpg",
                    "alt": "Utah Symphony Jan/Feb 2021–22 Cover",
                    "title": "Utah Symphony Jan/Feb 2021–22 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Jan/Feb 2021–22</div>
          </Link>
          <Link to="/broadway-2022" alt="Link to Bravo Broadway Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAcvlzDJNwENm7i0E5X//xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEREgMT/9oACAEBAAEFAoRaxBR4wLxslcFnOWGe50bNH//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8BjIV5/8QAGBEBAQADAAAAAAAAAAAAAAAAABIBAhH/2gAIAQIBAT8BvVWEuP/EAB0QAAEEAgMAAAAAAAAAAAAAAAEAAhAxESAhMkH/2gAIAQEABj8CtdhHpXDAsFoixr//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMRBxQVFh8f/aAAgBAQABPyFAurPyuJloo+1MVoe1gKx6JcvwME4I63NzG2zMOD//2gAMAwEAAgADAAAAEOvuPP/EABgRAQEAAwAAAAAAAAAAAAAAAAARASFB/9oACAEDAQE/EOy2iLl//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERQWH/2gAIAQIBAT8QdJpyJTrIP//EABwQAQEAAwEAAwAAAAAAAAAAAAERACExQRBRYf/aAAgBAQABPxBLqBdcc13ZLK+EC9lrhBUOLcqpQaMw2oJpBZNZoIf09eYyoKE1nQCPcJ5TJ+s//9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e7cb9911655956387b07c2e89d6f0d71/8ac56/bravo-broadway.webp 240w", "/static/e7cb9911655956387b07c2e89d6f0d71/d3be9/bravo-broadway.webp 480w", "/static/e7cb9911655956387b07c2e89d6f0d71/87ca7/bravo-broadway.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e7cb9911655956387b07c2e89d6f0d71/09b79/bravo-broadway.jpg 240w", "/static/e7cb9911655956387b07c2e89d6f0d71/7cc5e/bravo-broadway.jpg 480w", "/static/e7cb9911655956387b07c2e89d6f0d71/1a144/bravo-broadway.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/e7cb9911655956387b07c2e89d6f0d71/1a144/bravo-broadway.jpg",
                    "alt": "Utah Symphony Bravo Broadway 2022 Cover",
                    "title": "Utah Symphony Bravo Broadway 2022 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Bravo Broadway</div>
          </Link>
        </section>
      </details>
      <details>
        <summary>2021 Concerts</summary>
        <section className="home-list past">
          <Link to="/nov-dec2021" alt="Link to Nov/Dec 2021–22 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAABAwL/2gAMAwEAAhADEAAAAYGyW4IzkY1ZiyyzoY//xAAbEAACAgMBAAAAAAAAAAAAAAABAgMRABIxEP/aAAgBAQABBQJNbJjrBe0rBkHEIjWeQsBxZCEaj5//xAAZEQADAAMAAAAAAAAAAAAAAAAAAREDEjH/2gAIAQMBAT8BWOoZuoPp/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAER/9oACAECAQE/AdIf/8QAHxAAAQMDBQAAAAAAAAAAAAAAAQAQIQISQRETMlFx/9oACAEBAAY/ApC4qFD7k9IC0U59azC1uJLf/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBEGGBkf/aAAgBAQABPyFQ2/cuTb8xi6Qy+lo0mKIJ2+ITC524uJCk441N7xbQLuVymgCGsWz0TP/aAAwDAQACAAMAAAAQMwIB/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxA9NhilyExSX//EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QWNVGHY//xAAfEAEBAAICAgMBAAAAAAAAAAABEQBBITFRcWGBodH/2gAIAQEAAT8QOVm1q/WSenTsfzGh8AW/uSKuMF65zkyCqb0P3ggLZvLoV/EnqYSQCdTv4k694FOinrATTMSL7wBNChCHRnEQAAHgz//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/3587c96a2ed237573406dc756826dfe4/8ac56/novdec-cover.webp 240w", "/static/3587c96a2ed237573406dc756826dfe4/d3be9/novdec-cover.webp 480w", "/static/3587c96a2ed237573406dc756826dfe4/87ca7/novdec-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/3587c96a2ed237573406dc756826dfe4/09b79/novdec-cover.jpg 240w", "/static/3587c96a2ed237573406dc756826dfe4/7cc5e/novdec-cover.jpg 480w", "/static/3587c96a2ed237573406dc756826dfe4/1a144/novdec-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/3587c96a2ed237573406dc756826dfe4/1a144/novdec-cover.jpg",
                    "alt": "Utah Symphony Nov/Dec 2021–22 Cover",
                    "title": "Utah Symphony Nov/Dec 2021–22 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Nov/Dec 2021–22</div>
          </Link>
          <Link to="/holiday-pops2021" alt="Link to Holiday Pops Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFXwwsFI8Ci3NOLoM5X/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAIBERIDE//aAAgBAQABBQKEWpUo8YF42SuCznLDPc6Nmj//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/AYyFef/EABgRAQEAAwAAAAAAAAAAAAAAAAASAQIR/9oACAECAQE/Ab1VhLj/xAAcEAABBQADAAAAAAAAAAAAAAABAAIQETEgIUH/2gAIAQEABj8C1bceldMCotEaOP8A/8QAHhABAAICAgMBAAAAAAAAAAAAAQARITEQcUFRYZH/2gAIAQEAAT8hQbqwDX4cTLRR9qYrQ9rAVj0S5fgYJwR1ubmNtmYcH//aAAwDAQACAAMAAAAQq+48/8QAGBEBAQADAAAAAAAAAAAAAAAAABEBIUH/2gAIAQMBAT8Q7LaIuX//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhYf/aAAgBAgEBPxB0l05Ep1kH/8QAHBABAQEAAwEBAQAAAAAAAAAAAREAITFBURBh/9oACAEBAAE/EEgVKie6fP6iJ+IF7LjiCodLdVSg0ZjlQTSCycbgIf6e3mZUFCcbsAj3E9U0/N//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/4f5f239b84357a1f4f3d2d35ad6c734c/8ac56/holiday-pops.webp 240w", "/static/4f5f239b84357a1f4f3d2d35ad6c734c/d3be9/holiday-pops.webp 480w", "/static/4f5f239b84357a1f4f3d2d35ad6c734c/87ca7/holiday-pops.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/4f5f239b84357a1f4f3d2d35ad6c734c/09b79/holiday-pops.jpg 240w", "/static/4f5f239b84357a1f4f3d2d35ad6c734c/7cc5e/holiday-pops.jpg 480w", "/static/4f5f239b84357a1f4f3d2d35ad6c734c/1a144/holiday-pops.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/4f5f239b84357a1f4f3d2d35ad6c734c/1a144/holiday-pops.jpg",
                    "alt": "Utah Symphony Holiday Pops Cover",
                    "title": "Utah Symphony Holiday Pops Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Holiday Pops 2021–22</div>
          </Link>
          <Link to="/santa-claus2021" alt="Link to Here Comes Santa Claus Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHM4fsMk3AotWnFYFyv/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAIBERIDEyH/2gAIAQEAAQUC8/jJRR4wLxslcFnOWGe50bNH/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPwGMhXn/xAAYEQEBAAMAAAAAAAAAAAAAAAAAEgECEf/aAAgBAgEBPwG9VYS4/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACEBEgMSEiQf/aAAgBAQAGPwIdgt3HpXDAqLRGxj//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMRBxQVFh/9oACAEBAAE/IQqUXMHDpxMtFH2puUPawFY9EuX4GCcEdbm5jbZmHB//2gAMAwEAAgADAAAAEAvuPP/EABgRAQEAAwAAAAAAAAAAAAAAAAARASFB/9oACAEDAQE/EOy2iLl//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERQWH/2gAIAQIBAT8QdJpyJTrIP//EAB0QAQEAAwACAwAAAAAAAAAAAAERACExQVEQYXH/2gAIAQEAAT8Q1YStdn7vKwVe3PhAvItcMSw4tuVUoNGYbUE0gsms0EP2evGMqChNZ0AjzhPKZPrP/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/2e60b8bee156df4c2a31cfae5f763816/8ac56/santa-claus.webp 240w", "/static/2e60b8bee156df4c2a31cfae5f763816/d3be9/santa-claus.webp 480w", "/static/2e60b8bee156df4c2a31cfae5f763816/87ca7/santa-claus.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/2e60b8bee156df4c2a31cfae5f763816/09b79/santa-claus.jpg 240w", "/static/2e60b8bee156df4c2a31cfae5f763816/7cc5e/santa-claus.jpg 480w", "/static/2e60b8bee156df4c2a31cfae5f763816/1a144/santa-claus.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/2e60b8bee156df4c2a31cfae5f763816/1a144/santa-claus.jpg",
                    "alt": "Utah Symphony Here Comes Santa Claus Cover",
                    "title": "Utah Symphony Here Comes Santa Claus Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Here Comes Santa Claus! 2021–22</div>
          </Link>
          <Link to="/pink-martini2021" alt="Link to Pink Martini 2021–22 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFVywsFI8Ci3NOLoM5X/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECERIDE//aAAgBAQABBQJRgNIo8UR42OOCzm5Ep29GzR//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/AYyFef/EABgRAQEAAwAAAAAAAAAAAAAAAAASAQIR/9oACAECAQE/Ab1VhLj/xAAcEAABBAMBAAAAAAAAAAAAAAABAAIQIREgMUH/2gAIAQEABj8C6VUelUwLBaI6Nf/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFxEDFBUWH/2gAIAQEAAT8hoFg6lrNdnEy0UfamK0PawFY6JcvwME4I13O5jbszDg//2gAMAwEAAgADAAAAEKvuPP/EABgRAQEAAwAAAAAAAAAAAAAAAAARASFB/9oACAEDAQE/EOy2iLl//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIWH/2gAIAQIBAT8QdJdORKdZB//EABwQAQEAAwEAAwAAAAAAAAAAAAERACExQRBRYf/aAAgBAQABPxBGrG5ccOAGbYfCBey1wgqHFuVUoNGYbUE0gsms0EP6evMZUFCazoBHuE8pk/Wf/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/850f84ac303fbf88a42dbf7652fe715f/8ac56/pink-martini.webp 240w", "/static/850f84ac303fbf88a42dbf7652fe715f/d3be9/pink-martini.webp 480w", "/static/850f84ac303fbf88a42dbf7652fe715f/87ca7/pink-martini.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/850f84ac303fbf88a42dbf7652fe715f/09b79/pink-martini.jpg 240w", "/static/850f84ac303fbf88a42dbf7652fe715f/7cc5e/pink-martini.jpg 480w", "/static/850f84ac303fbf88a42dbf7652fe715f/1a144/pink-martini.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/850f84ac303fbf88a42dbf7652fe715f/1a144/pink-martini.jpg",
                    "alt": "Utah Symphony Nov/Dec 2021–22 Cover",
                    "title": "Utah Symphony Nov/Dec 2021–22 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Pink Martini 2021</div>
          </Link>
          <Link to="/salute-youth2021" alt="Link to Salute to Youth 2021–22 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwH/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABJ7VKjKQvDuWsijEX/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERAhIhA//aAAgBAQABBQK/OOUWkhtMekOnp6LkmDnB/8QAGREAAwEBAQAAAAAAAAAAAAAAAAECERJR/9oACAEDAQE/AVbO68FCHGvT/8QAGhEAAgIDAAAAAAAAAAAAAAAAABIBAhNRYf/aAAgBAgEBPwFIFrscycP/xAAbEAACAQUAAAAAAAAAAAAAAAAAMSEBECBBcf/aAAgBAQAGPwKSMFdEvoqmz//EAB0QAQACAQUBAAAAAAAAAAAAAAEAESEQYXGB0UH/2gAIAQEAAT8hEAG63gGRTjQfIXtL27KziWPU2XohU+9keTqEYLCz00//2gAMAwEAAgADAAAAEFQmvv/EABwRAQABBAMAAAAAAAAAAAAAAAEAITFB8FFxof/aAAgBAwEBPxAFivcRvCKm+QmWd4n/xAAdEQEAAQMFAAAAAAAAAAAAAAABABARIVFxkbHw/9oACAECAQE/ENRxtSLMJ7mAC3Sf/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFBgZGx4f/aAAgBAQABPxChVi0OXgj06QqPsx4fdzMrvZp9ylERS+rAPYOl1gwGPHTAyr3BGgOOW0qNppb7CZidDz+z/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/a5e001e459a96c688ca22def40e710f5/8ac56/salute-youth.webp 240w", "/static/a5e001e459a96c688ca22def40e710f5/d3be9/salute-youth.webp 480w", "/static/a5e001e459a96c688ca22def40e710f5/87ca7/salute-youth.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/a5e001e459a96c688ca22def40e710f5/09b79/salute-youth.jpg 240w", "/static/a5e001e459a96c688ca22def40e710f5/7cc5e/salute-youth.jpg 480w", "/static/a5e001e459a96c688ca22def40e710f5/1a144/salute-youth.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/a5e001e459a96c688ca22def40e710f5/1a144/salute-youth.jpg",
                    "alt": "Utah Symphony Salute to youth 2021–22 Cover",
                    "title": "Utah Symphony Salute to youth 2021–22 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Salute to Youth 2021</div>
          </Link>
          <Link to="/sept-oct2021" alt="Link to September/October 2021 Utah Symphony publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAB/9oADAMBAAIQAxAAAAHmZtIxbINVFOGRkv8A/8QAGxABAAIDAQEAAAAAAAAAAAAAAQIRAAMxEBL/2gAIAQEAAQUCgwtlqpyIOxCOw5xaQ5RnzHz/xAAXEQEBAQEAAAAAAAAAAAAAAAABAAIx/9oACAEDAQE/AXUThjl//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHRAAAQQCAwAAAAAAAAAAAAAAAAEQESEiYQIxUf/aAAgBAQAGPwLJCkKMpjR7xllNtZ23/8QAHxABAAIBAwUAAAAAAAAAAAAAAQARIRBhkTFBUXGx/9oACAEBAAE/IQD6S56vEouFHuFTcw2rFhuaQ9xmZgXyNIRyY0YXECip/9oADAMBAAIAAwAAABC063//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EMXktBkKxwF//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EEFmT//EAB8QAQACAwABBQAAAAAAAAAAAAEAESExQWFxkaGx4f/aAAgBAQABPxC8FralPb9jcq+Dh6OaiapwG3zDxSfJO5iF9o7sAWuDkVzgNjqI+Z0gVXEYKEU0BOFywstawPuFU1P/2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/d215427d7382a803643b6cd0fe75d18a/8ac56/sept-oct.webp 240w", "/static/d215427d7382a803643b6cd0fe75d18a/d3be9/sept-oct.webp 480w", "/static/d215427d7382a803643b6cd0fe75d18a/87ca7/sept-oct.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/d215427d7382a803643b6cd0fe75d18a/09b79/sept-oct.jpg 240w", "/static/d215427d7382a803643b6cd0fe75d18a/7cc5e/sept-oct.jpg 480w", "/static/d215427d7382a803643b6cd0fe75d18a/1a144/sept-oct.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/d215427d7382a803643b6cd0fe75d18a/1a144/sept-oct.jpg",
                    "alt": "Utah Symphony September/October 2021 Cover",
                    "title": "Utah Symphony September/October 2021 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Sept/Oct 2021</div>
          </Link>
        </section>
      </details>
    </div>
    <hr />
    <br />
    <blockquote>
      <p parentName="blockquote">{`Utah Symphony is celebrating years of entertainment. Featuring music spanning 250 years of history—Beethoven, Tchaikovsky, Rachmaninoff, Brahms, Mozart, Bach and more!`}</p>
    </blockquote>
    {
      /******************ADS ADS ADS******************/
    }
    {
      /******************ADS ADS ADS******************/
    }
    {
      /******************ADS ADS ADS******************/
    }
    <OutboundLink href="https://osher.utah.edu/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=utahsymphony" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEBQP/xAAYAQACAwAAAAAAAAAAAAAAAAACAwABBP/aAAwDAQACEAMQAAAB05JFS2k6B1EhbHoa4WT/xAAeEAACAgICAwAAAAAAAAAAAAABAgADBBIRIhMhI//aAAgBAQABBQLYGW2fQ2ss8gjv1qtq0Yrv3aV4XK7esZS0QEL/AP/EABoRAAICAwAAAAAAAAAAAAAAAAECABEDEFH/2gAIAQMBAT8BFCWnJkQBb1//xAAXEQADAQAAAAAAAAAAAAAAAAAAASEQ/9oACAECAQE/Aay7/8QAHxAAAgIBBAMAAAAAAAAAAAAAAAECESIDEiExEGFx/9oACAEBAAY/AnkbbxKRFLs4rgy79m6OmUoWXN0/E2vhTZ//xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPyGkFu9ItbDIIx2iz9ijv9ToWlaQIqHvq4rJPNYiNM9yJcvA7RAgPksfVmv2AkL9n//aAAwDAQACAAMAAAAQW+d8/8QAGREAAwADAAAAAAAAAAAAAAAAAAERECFB/9oACAEDAQE/EIND6BoRSY//xAAYEQADAQEAAAAAAAAAAAAAAAAAARExIf/aAAgBAgEBPxDtWJTotKf/xAAeEAEAAgIDAQEBAAAAAAAAAAABABEhMUFRYYGh8P/aAAgBAQABPxADSrY65r9IRYzQAuFV+xCDeOePPIgKwdGw0wro2US1M0nHkN0mfA2LxETrLDQ/DEXvtRL4vVd81LeItL4Be44eAalktcDCWu9/1wabu07n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5af6749032bf105048da332d251487dd/8ac56/u-of-u.webp 240w", "/static/5af6749032bf105048da332d251487dd/d3be9/u-of-u.webp 480w", "/static/5af6749032bf105048da332d251487dd/87ca7/u-of-u.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5af6749032bf105048da332d251487dd/09b79/u-of-u.jpg 240w", "/static/5af6749032bf105048da332d251487dd/7cc5e/u-of-u.jpg 480w", "/static/5af6749032bf105048da332d251487dd/1a144/u-of-u.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5af6749032bf105048da332d251487dd/1a144/u-of-u.jpg",
              "alt": "University of Utah Lifelong Learning",
              "title": "University of Utah Lifelong Learning",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>
    <OutboundLink href="https://www.jerryseinercadillac.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=utahsymphony" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAABAABAwL/xAAXAQADAQAAAAAAAAAAAAAAAAAAAwQC/9oADAMBAAIQAxAAAAHl4XxReORaBaTWRIiwf//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQISEP/aAAgBAQABBQLgeTk6Q9K1Fr1k6ZS+f//EABkRAAIDAQAAAAAAAAAAAAAAAAARAQISQf/aAAgBAwEBPwFx0VRmj//EABcRAQEBAQAAAAAAAAAAAAAAAAEAERD/2gAIAQIBAT8BFteZf//EABYQAQEBAAAAAAAAAAAAAAAAACARMf/aAAgBAQAGPwIQaP/EABwQAAICAgMAAAAAAAAAAAAAAAABEXEhUTFBYf/aAAgBAQABPyFldUhhu0LSLI5+RQlLmxEJ+bI+whUbyf/aAAwDAQACAAMAAAAQB9IN/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERYf/aAAgBAwEBPxC1Oi7af//EABgRAAIDAAAAAAAAAAAAAAAAAAAQEVFh/9oACAECAQE/EKJgQn//xAAdEAEAAwACAwEAAAAAAAAAAAABABEhMVFBkdGB/9oACAEBAAE/EBtvPyL4ChXZFHiaNAWtSAFiaSxr1DsRZE2Ngqh9pQEbt+oEArOOIAaW979lK3p5fsVSYdXP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b03bb8d1edd271dd8e530b37482e930e/8ac56/jerry-seiner.webp 240w", "/static/b03bb8d1edd271dd8e530b37482e930e/d3be9/jerry-seiner.webp 480w", "/static/b03bb8d1edd271dd8e530b37482e930e/87ca7/jerry-seiner.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b03bb8d1edd271dd8e530b37482e930e/09b79/jerry-seiner.jpg 240w", "/static/b03bb8d1edd271dd8e530b37482e930e/7cc5e/jerry-seiner.jpg 480w", "/static/b03bb8d1edd271dd8e530b37482e930e/1a144/jerry-seiner.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b03bb8d1edd271dd8e530b37482e930e/1a144/jerry-seiner.jpg",
              "alt": "Jerry Seiner Cadillac",
              "title": "Jerry Seiner Cadillac",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      